@font-face {
    font-family: 'Museo Sans';
    font-weight: 400;
    src: url('assets/fonts/museosans-regular.eot') format('eot');
    src: url('assets/fonts/museosans-regular.otf') format('opentype'),
        url('assets/fonts/museosans-regular.woff') format('woff');
}

@font-face {
    font-family: 'Museo Sans';
    font-weight: 500;
    src: url('assets/fonts/museosans-semibold.eot') format('eot');
    src: url('assets/fonts/museosans-semibold.otf') format('opentype'),
        url('assets/fonts/museosans-semibold.woff') format('woff');
}

@font-face {
    font-family: 'Museo Sans';
    font-weight: 300;
    src: url('assets/fonts/museosans-thin.eot') format('eot');
    src: url('assets/fonts/museosans-thin.otf') format('opentype'),
        url('assets/fonts/museosans-thin.woff') format('woff');
}

body {
    margin: 0;
    font-family: 'Museo Sans', sans-serif;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: grayscale;
    background: linear-gradient(
        180deg,
        #143a61 0%,
        #133457 220px,
        #0c233a 100%
    );
    color: #fff;
    min-height: 100vh;
}

button {
    cursor: pointer;
}

.ReactModalPortal {
    z-index: 9999;
    position: relative;
}
